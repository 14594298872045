import React, { Component } from 'react';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import { Route, Router } from 'react-router-dom';


import jquery from 'jquery';
import Careers from "./Careers";
import People from "./People";
import MainHome from "./MainHome";
import PeopleDetail from "./PeopleDetail";
import BIM from "./BIM";
import GDPR from "./GDPR";
import Consultation from "./Consultation";
import Architecture from "./Architecture";
import TrafficInfrastructure from "./TrafficInfrastructure";
import Contact from "./Contact";
import Projects from "./Projects";
import ProjectsDetail from "./ProjectsDetail";
import About from "./About";
import ServicesExpertise from "./ServicesExpertise";
import * as Sentry from '@sentry/browser';
import PrivacyPolicy from "./PrivacyPolicy";
import {Trans, withTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import QualityPolitics from "./QualityPolitics";
import getLocalizedRoute from "./Utils/localizedRoute";


const createHistory = require("history").createBrowserHistory;

const $ = jquery;

const history = createHistory();

window.routes = {};



class Home extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: null,
            eventId: null
        };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error });
        Sentry.withScope(scope => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({eventId})
        });
    }

    componentWillMount() {
        const { i18n, t } = this.props;

        this.prepareRoutes();

        let url = new URL(window.location.href);
        switch (url.pathname.slice(0, 3)) {
            case '/cs': i18n.changeLanguage('cs'); break;
            case '/sk': i18n.changeLanguage('sk'); break;
            case '/ru': window.location.href="/"; break; // i18n.changeLanguage('ru'); break;
            default:
                if (undefined === window.Cookies.get('locale') && (url.pathname === '/' || url.pathname === "")) {
                    window.location.href = getLocalizedRoute('sk', this.props.t);
                }
                i18n.changeLanguage('en');
        }



        this.unlisten = history.listen((location, action) => {
            $("#home-menu-li").removeClass("active");
            $("#projects-menu-li").removeClass("active");
            $("#about-us-menu-li").removeClass("active");
            $("#careers-menu-li").removeClass("active");
            $("#people-menu-li").removeClass("active");
            $("#bim-menu-li").removeClass("active");
            $("#contact-menu-li").removeClass("active");
            $("#services-expertise-menu-li").removeClass("active");

            if(window.location.pathname.length === 1){
                $("#home-menu-li").addClass("active");
            }

            if(window.location.pathname.includes("projects")){
                $("#projects-menu-li").addClass("active");
            }
            if(window.location.pathname.includes("about-us")){
                $("#about-us-menu-li").addClass("active");
            }
            if(window.location.pathname.includes("careers")){
                $("#careers-menu-li").addClass("active");
            }
            if(window.location.pathname.includes("people")){
                $("#people-menu-li").addClass("active");
            }
            if(window.location.pathname.includes("bim")){
                $("#bim-menu-li").addClass("active");
            }
            if(window.location.pathname.includes("contact")){
                $("#contact-menu-li").addClass("active");
            }
            if(window.location.pathname.includes("services-expertise")){
                $("#services-expertise-menu-li").addClass("active");
            }

        });


    }

    prepareRoutes() {
        const { i18n, t } = this.props;

        let routes = window.routes;
        routes['/'] = '';
        routes['/' + t("route.projects")] = 'route.projects';
/*        routes['/' + t("route.about-us")] = 'route.about-us';*/
        routes['/' + t("route.careers")] = 'route.careers';
        routes['/' + t("route.people")] = 'route.people';
        routes['/' + t("route.bim")] = 'route.bim';
        routes['/' + t("route.consultation")] = 'route.consultation';
        routes['/' + t("route.traffic-infrastructure")] = 'route.traffic-infrastructure';
        routes['/' + t("route.architecture")] = 'route.architecture';
        routes['/' + t("route.contact")] = 'route.contact';
        routes['/' + t("route.services-expertise")] = 'route.services-expertise';
        routes['/' + t("route.privacy-policy")] = 'route.privacy-policy';
        routes['/' + t("route.terms-of-use")] = 'route.terms-of-use';
        routes['/' + t("route.gdpr")] = 'route.gdpr';
        routes['/' + t("route.quality-politics")] = 'route.quality-politics';
    }



    componentDidMount(){


        $("#home-menu-li").removeClass("active");
        $("#projects-menu-li").removeClass("active");
        $("#about-us-menu-li").removeClass("active");
        $("#careers-menu-li").removeClass("active");
        $("#people-menu-li").removeClass("active");
        $("#bim-menu-li").removeClass("active");
        $("#contact-menu-li").removeClass("active");
        $("#services-expertise-menu-li").removeClass("active");

        if(window.location.pathname.length === 1){
            $("#home-menu-li").addClass("active");
        }

        if(window.location.pathname.includes("projects")){
            $("#projects-menu-li").addClass("active");
        }
        if(window.location.pathname.includes("about-us")){
            $("#about-us-menu-li").addClass("active");
        }
        if(window.location.pathname.includes("careers")){
            $("#careers-menu-li").addClass("active");
        }
        if(window.location.pathname.includes("people")){
            $("#people-menu-li").addClass("active");
        }
        if(window.location.pathname.includes("bim")){
            $("#bim-menu-li").addClass("active");
        }
        if(window.location.pathname.includes("contact")){
            $("#contact-menu-li").addClass("active");
        }
        if(window.location.pathname.includes("services-expertise")){
            $("#services-expertise-menu-li").addClass("active");
        }
    }

  render() {
      const {i18n, t } = this.props;
      this.prepareRoutes();

      return (
        <Router history={history}>
            <div className="App">
                <Helmet>
                    <title>{i18n.exists('seo.title') ? t('seo.title') : "Siebert+Talaš"}</title>
                    <meta name="description" content={i18n.exists('seo.description') ? t('seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('seo.title') ? t('seo.title') : ""} />
                    <meta property="og:description" content={i18n.exists('seo.description') ? t('seo.description') : ""} />
                </Helmet>
                <Header value={this.state.value} />
                <Route exact path={'/' + (i18n.language !== 'en' ? i18n.language + '/' : '')}  component={MainHome} />
                <Route exact path={'/' +  t('route.projects')} component={Projects} />
                <Route exact path={'/' +  t('route.projects') + '/:id'} component={ProjectsDetail} />
              {/*  <Route exact path={'/' +  t('route.about-us')} component={About} />*/}
                <Route exact path={'/' +  t('route.careers')}  component={Careers} />
                <Route exact path={'/' +  t('route.people') }  component={People} />
                <Route exact path={'/' +  t('route.people')  + '/:id'}  component={PeopleDetail} />
                <Route exact path={'/' +  t('route.bim')  }  component={BIM} />
                <Route exact path={'/' +  t('route.consultation')   }  component={Consultation} />
                <Route exact path={'/' +  t('route.traffic-infrastructure')   } component={TrafficInfrastructure} />
                <Route exact path={'/' +  t('route.architecture')   } component={Architecture} />
                <Route exact path={'/' +  t('route.contact')  }  component={Contact} />
                <Route exact path={'/' +  t('route.services-expertise')   } component={ServicesExpertise} />
                <Route exact path={'/' +  t('route.privacy-policy')  } component={PrivacyPolicy} />
                <Route exact path={'/' +  t('route.gdpr')  } component={GDPR} />
                <Route exact path={'/' +  t('route.quality-politics')  } component={QualityPolitics} />
                <Footer />
            </div>
        </Router>
      );
  }
}

export default  withTranslation()(Home);
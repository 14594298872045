import React, { Component } from 'react';
import logoFooter from './img/logopaticka.svg';
import facebookFooter from './img/facebook-footer.svg';
import instagramFooter from './img/instagram-footer.svg';
import LinkedInFooter from './img/in-icon.svg';
import arrowRightWhite from './img/arrow-right-white.svg';
import TwitterFooter from './img/twitter-footer.svg';
import {Link} from 'react-router-dom';
import './App.css';

import ArrowRightBlack from "./img/arrow-right-black.svg";

import ArrowRightYellow from "./img/arrow-right-yellow.svg";
import ArrowDownBlack from "./img/arrow-down-black.svg";
import ArrowDownYellow from "./img/arrow-down-yellow.svg";
import HFLogo from "./img/logo_hf_white.gif";
import $ from "jquery";
import {withTranslation, Trans} from "react-i18next";
import Cookies from 'universal-cookie';
const cookies = new Cookies();



class Footer extends Component {
    constructor(props){
        super(props);
        this.acceptCookies = this.acceptCookies.bind(this);
    }
    componentDidMount() {

        if(cookies.get('dolniLista') === undefined){
            cookies.set('dolniLista', "false", { path: '/' });
        }

        this.checkCookies();
    }


    checkCookies(){

        if(cookies.get('dolniLista') === "true"){

            $("#cookie-lista").hide();
        }
    }

    acceptCookies(){
        cookies.set('dolniLista', "true", { path: '/' });
        this.checkCookies();
    }
    render() {
      const { t } = this.props;
    return (
<div>

    <footer className="footer">



        <div className="container-fluid" id="cookie-lista">
            <div className="container">
                <span dangerouslySetInnerHTML={{__html: t('footer.cookies-lista.text', {interpolation: {escape: false}})}} />
                <button onClick={this.acceptCookies} dangerouslySetInnerHTML={{__html: t('footer.cookies-lista.button', {interpolation: {escape: false}})}} />
            </div>
        </div>

        <div className="container footer-container">

            <div className="row">

                <div className="col-md-4 footer-block footer-menu">
                    <img src={logoFooter} alt="Logo Footer" className="footer-section-headline"/>

                    <ul>

                        <li><Link to={'/' +  t('route.projects')}><Trans i18nKey={'footer.link.projects'}/></Link></li>
                        <li><Link to={'/' +  t('route.services-expertise')}><Trans i18nKey={'footer.link.services-expertise'}/></Link></li>
                        <li><Link to={'/' + t('route.about-us') }><Trans i18nKey={'footer.link.about-us'}/></Link></li>
                        <li><Link to={'/' + t('route.careers')}><Trans i18nKey={'footer.link.careers'}/></Link></li>
                        <li><Link to={'/' +  t('route.contact')}><Trans i18nKey={'footer.link.contact'}/></Link></li>
                    </ul>
                </div>

                <div className="col-md-4  follow-us  footer-block">
                    <h2 className="footer-section-headline" dangerouslySetInnerHTML={{__html: t('footer.follow-us.headline', {interpolation: {escape: false}})}} />

                    <ul>
                        <li><a href="https://www.facebook.com/sieberttalas/" target="_blank"  rel="noopener noreferrer"><img src={facebookFooter} alt="Facebook icon" />/sieberttalas</a></li>
                        <li><a href="https://www.instagram.com/sieberttalas/"  target="_blank"  rel="noopener noreferrer"><img src={instagramFooter} alt="Instagram icon" />/sieberttalas</a></li>

                        <li><a href="https://cz.linkedin.com/company/siebert-tala%C5%A1"  target="_blank"  rel="noopener noreferrer"><img src={LinkedInFooter} alt="Linkedin icon" />/siebert-talaš</a></li>
                        <li><a href="https://twitter.com/sieberttalas/"  target="_blank"  rel="noopener noreferrer"><img src={TwitterFooter} alt="Twitter icon" />/sieberttalas</a></li>

                    </ul>

                </div>

                <div className="col-md-4 footer-block footer-block--hiring">
                    <h2 className="footer-section-headline" dangerouslySetInnerHTML={{__html: t('footer.hiring.headline', {interpolation: {escape: false}})}} />
                    <span dangerouslySetInnerHTML={{__html: t('footer.hiring.description', {interpolation: {escape: false}})}} />

                    <button className="opened-positions-mobile arrow-right-white"><Link to={'/'+ t('route.careers')}><Trans i18nKey={'footer.hiring.opened-positions-button'}/></Link></button>

                    <div className="opened-positions">
                        <div>
                            <Link to={'/'+ t('route.careers')}><Trans i18nKey={'footer.hiring.opened-positions-button'}/> <img src={arrowRightWhite} alt="Arrow right"/></Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 follow-us-mobile  footer-block">
                    <h2 className="footer-section-headline" dangerouslySetInnerHTML={{__html: t('footer.follow-us.headline', {interpolation: {escape: false}})}} />

                    <ul>
                        <li><a href="https://www.facebook.com/sieberttalas/" target="_blank"  rel="noopener noreferrer"><img src={facebookFooter} alt="Facebook icon"/></a></li>
                        <li><a href="https://www.instagram.com/sieberttalas/"  target="_blank"  rel="noopener noreferrer"><img src={instagramFooter}  alt="Instagram icon"/></a></li>
                        <li><a href="https://cz.linkedin.com/company/siebert-tala%C5%A1"  target="_blank"  rel="noopener noreferrer"><img src={LinkedInFooter} alt="Linkedin icon" /></a></li>
                        <li><a href="https://twitter.com/sieberttalas/"  target="_blank"  rel="noopener noreferrer"><img src={TwitterFooter} alt="Twitter icon" /></a></li>
                    </ul>
                </div>
        </div>

            <div className="row privacy-policy">
                <div className="col-md-8 inner-privacy">
                    <ul>
                        <li><Link to={'/' + t('route.privacy-policy')}><Trans i18nKey={'footer.privacy-policy.headline'}/></Link></li>
                        <li><Trans i18nKey={'footer.all-rights-reserved.headline'}/></li>
                    </ul>
                </div>
                <div className="col-md-4 text-center">
                    <a href="https://hf.cz/"><img src={HFLogo} alt="created by Hencehorth." id="hf_logo"/></a>
                </div>
            </div>

        </div>

        <div id="preload">
            <img src={ArrowRightYellow} alt=""/>
            <img src={ArrowRightBlack} alt="" />
            <img src={ArrowDownBlack} alt=""/>
            <img src={ArrowDownYellow} alt=""/>
        </div>
    </footer>

</div>
    );
  }
}

export default withTranslation()(Footer);
